import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
export default {
  mixins: [billMgrToolActions, getLocalizedPeriod],
  data() {
    return {
      mp_dataset: {},
      mp_modal: null,
      mp_total: 0,
      mp_resultSuccess: [],
      mp_resultError: [],
      mp_next: false,
    };
  },
  computed: {
    balance() {
      return this.$store.state.moduleProfile.profile.balance;
    },
    tools() {
      const splitted = this.moduleMain.split('.');
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path.tools;
    },
    moduleMainPath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    mp_isBalanceEnough() {
      return this.mp_total <= this.balance;
    },
  },
  // watch: {
  //   mp_isBalanceEnough: function (value) {
  //     if (this.mp_modal) this.mp_toggleConfirmButtonState(value);
  //   },
  // },
  methods: {
    multiProlong(list) {
      const promises = [];
      list.forEach(tariff => {
        promises.push(this.mp_fetchSingle(tariff));
      });
      this.$modals.open({
        name: 'MultiProlong',
        title: this.$t('prolong.title'),
        component: BaseLoader,
        onOpen: inst => (this.mp_modal = inst),
        onClose: () => {
          this.mp_modal = null;
          this.mp_reset();
        },
        onDismiss: () => {
          if (!this.mp_next) this.$modals.close({ name: this.mp_modal.name });
        },
        footer: {
          confirm: {
            props: { title: this.$t('prolong.confirm'), disabled: true },
            on: {
              click: () => {
                this.mp_order(true);
              },
            },
          },
          cancel: {
            props: { title: this.$t('prolong.order'), disabled: true },
            on: {
              click: () => this.mp_order(),
            },
          },
        },
      });
      Promise.all(promises).then(() => {
        Vue.set(this.mp_modal, 'size', 'big');
        Vue.set(this.mp_modal, 'props', {
          dataset: this.mp_dataset,
        });
        Vue.set(this.mp_modal, 'on', {
          ready: () => {
            Vue.set(this.mp_modal.footer.cancel.props, 'disabled', false);
            // this.mp_toggleConfirmButtonState(this.mp_isBalanceEnough);
          },
          change: value => {
            Object.keys(value).forEach(key => {
              if (this.mp_dataset[key].value !== value[key]) {
                this.mp_dataset[key].value = value[key];
                this.mp_dataset[key].period = value[key].k;
              }
            });
          },
          ['change-total']: value => {
            this.mp_total = value;
          },
        });
        Vue.set(this.mp_modal, 'component', this.multiProlongComponent);
      });
    },
    mp_fetchSingle(tariff) {
      const params = {
        id: tariff.id,
        func: this.tools.prolong.func,
      };
      return this.fetchBillMgrAction(params).then(data => {
        const { model, slist } = data;
        if (model && slist && model.period) {
          const list = slist.period.map(i => {
            return {
              ...i,
              label: this.getLocalizedPeriod(i.k),
            };
          });
          this.mp_dataset[tariff.id] = {
            tariff,
            model,
            list,
            period: model.period,
            value: list.find(i => i.k === model.period),
          };
        }
      });
    },
    mp_sendSingle(item, skip = false) {
      const params = {
        elid: item.tariff.id,
        func: this.tools.prolong.func,
        period: item.period,
        newface: 'on',
        sok: 'ok',
      };
      if (skip) params.skipbasket = 'on';
      else {
        params.clicked_button = 'basket';
        params.snext = '';
        params.newbasket = '';
      }
      return this.sendBillMgrAction(params)
        .then(data => {
          if (data && data.ok) this.mp_resultSuccess.push(item.tariff.id);
          else this.mp_resultError.push(item.tariff.id);
        })
        .catch(() => this.mp_resultError.push(item.tariff.id));
    },
    // mp_toggleConfirmButtonState(value) {
    //   Vue.set(this.mp_modal.footer.confirm.props, 'disabled', !value);
    //   const note = {
    //     text: this.$t('balance.notenough'),
    //     color: 'error',
    //   };
    //   Vue.set(this.mp_modal.footer, 'note', value ? null : note);
    // },
    mp_order(skip = false) {
      this.mp_next = true;
      let modal = null;
      this.$modals.open({
        name: 'MultiProlongResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      const promises = [];
      Object.values(this.mp_dataset).forEach(item => {
        promises.push(this.mp_sendSingle(item, skip));
      });
      Promise.all(promises).finally(() => {
        this.mp_reset();
        this.mp_next = false;
        this.$store.dispatch(`${this.moduleMainPath}/updateList`);
        this.$store.dispatch('moduleBasket/fetchBasket');
        this.$store.dispatch('moduleProfile/setProfileInfo');
        this.$modals.close({ name: 'MultiProlong' });
        let text = '';
        if (this.mp_resultSuccess.length) {
          const key = skip ? 'success_order' : 'success_basket';
          text += this.$t(`prolong.${key}`, { msg: '#' + this.mp_resultSuccess.join(', #') }) + ' ';
        }
        if (this.mp_resultError.length) {
          text += this.$t('prolong.error_result', { msg: '#' + this.mp_resultError.join(', #') });
        }
        Vue.set(modal, 'text', text);
        Vue.set(modal, 'component', null);
        Vue.set(modal, 'closable', true);
        Vue.set(modal, 'footer', {
          confirm: {
            props: { title: this.$t('good') },
            on: {
              click: () => {
                this.$modals.close();
                this.mp_resetRes();
              },
            },
          },
        });
      });
    },
    mp_reset() {
      this.mp_dataset = {};
    },
    mp_resetRes() {
      this.mp_resultSuccess = [];
      this.mp_resultError = [];
    },
  },
};
