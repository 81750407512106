<template>
  <main-card class="tariff-card">
    <template #header>
      <router-link
        :to="{ name: 'rentDetail', params: { id: tariff.id } }"
        class="tariff-card__title-high big-title"
      >
        {{ tariff.title }}
      </router-link>
    </template>
    <template #headerAction>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="bottom-end"
        :popper-options="popperOptions"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-card__popover"
      >
        <plain-button icon="more" class="tariff-card__more" @click="togglePopoverState" />
        <rent-context-menu slot="popover" :tariff="tariff" class="tariff-card__context-menu" />
      </v-popover>
    </template>
    <template #headerEnd>
      <main-label :color="tariff.status.color" class="tariff-card__label">
        {{ $t(`status.${tariff.status.key}`) }}
      </main-label>
    </template>
    <div class="tariff-card__content">
      <div class="tariff-card__title medium-title">
        {{ `${tariff.pricelist} (${$tc('period.month_c', tariff.period)})` }}
      </div>
      <div class="tariff-card__activity">
        <plain-button
          v-if="tariff.promise !== 'null'"
          v-tooltip="{
            content: promiseOn,
            autoHide: false,
            placement: 'top-start',
            container: false,
          }"
          icon="promise-icon"
          class="allowed-user-rights__promise"
        />
        <div class="tariff-card__note note-text">
          {{
            $t(`${isPast ? 'wasActiveUnder' : 'isActiveUnder'}`, {
              date: $d(tariff.expiredate, 'short'),
            })
          }}
        </div>
        <div class="tariff-card__activity-actions">
          <rent-autoprolong
            v-tooltip="{
              content: isAutoProlong,
              autoHide: false,
              placement: 'top-end',
            }"
            :tariff="tariff"
            class="tariff-card__autoprolong"
          />
          <plain-button
            v-if="hasProlongTool"
            color="primary"
            class="tariff-card__prolong"
            @click="costAutoProlong === '0.00' ? singleProlongFree(tariff) : singleProlong(tariff)"
          >
            {{ $t('prolong.action') }}
          </plain-button>
        </div>
      </div>
    </div>
    <template #footer>
      <tariff-spec v-bind="tariff.specs" />
    </template>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import TariffSpec from './TariffSpec';
import RentContextMenu from '../../../components/RentContextMenu.vue';
import RentAutoprolong from '../../../components/RentAutoprolong';
import RentProlongSingle from '../../../components/RentProlongSingle.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import popoverFix from '@/mixins/popoverFix';
import RentProlongSinglePayments from '@/layouts/RentServer/components/RentProlongSinglePayments';
import prolongText from '@/mixins/prolongText';
export default {
  name: 'TariffCard',
  components: { MainCard, MainLabel, TariffSpec, RentContextMenu, RentAutoprolong },
  mixins: [prolongSingle, prolongSingleFree, popoverFix, prolongText],
  props: {
    tariff: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      costAutoProlong: 0,
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
      moduleMain: 'moduleRent',
      singleProlongComponentFirst: RentProlongSingle,
      singleProlongComponent: RentProlongSinglePayments,
    };
  },
  computed: {
    isAutoProlong() {
      return this.tariff.autoprolong_period !== 'null'
        ? 'Автопродление включено'
        : 'Включить автопродление';
    },
    isPast() {
      return this.tariff.expiredate < Date.now();
    },
    tools() {
      return this.$store.state.moduleRent.tools;
    },
    cost() {
      return this.tariff.cost;
    },
    hasProlongTool() {
      return (
        this.tariff.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.tariff)
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.popperOptions.modifiers.flip.boundary = this.$parent.$el;
    });
    if (this.$parent.$children.findIndex(i => i === this) < 2) {
      this.popperOptions.modifiers.flip.enabled = false;
    }
    if (this.hasProlongTool) this.getCost();
  },
};
</script>
<i18n>
{
  "ru": {
    "promise" : "Для услуги активирован обещанный платеж до {promise}",
    "isActiveUnder": "Действует до {date}",
    "wasActiveUnder": "Действовал до {date}",
    "changepricelist": {
      "title": "Смена тарифа",
      "success": "Смена тарифа прошла успешно. Данные о тарифе скоро обновятся."
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "free": "Активировать",
      "cancel": "Отмена",
      "confirm": "Оплатить",
      "order": "В корзину",
      "action": "Продлить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-rights {
  &__promise {
    margin-right: 1px;
  }
}
.tariff-card {
  min-height: 100%;

   &__title {
    text-decoration: none;
    color: inherit;

    &-high {
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }
  }
  &__ip {
    margin-top: 2px;
    +breakpoint(sm-and-up) {
      margin-top: 20px;
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      margin-top: 0.25rem;
    }
  }
  &__more {
    display: block;
    margin-right: -0.5rem;

    +breakpoint(sm-and-up) {
      margin-right: -1rem;
      margin-top: -2px;
    }
  }
  &__activity {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }

    &-actions {
      flexy(flex-start, baseline);
      margin-top: 0.75rem;

      +breakpoint(sm-and-up) {
        margin-left: 1.5rem;
      }
    }
  }
  &__autoprolong {
    align-self: center;

    //& + ^[0]__prolong {
    //  margin-left: 1.5rem;
    //}
  }
  &__prolong {
    +breakpoint(ms-and-down) {
      margin-top: 0.75rem;
    }

    +breakpoint(sm-and-up) {
      margin-left: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
