import { parseSizeGb } from '@/utils/parsers/sizeParser';
export default {
  methods: {
    getLocalizedSize(obj) {
      let parsed = parseSizeGb(obj.value, obj.unit);
      return this.$t(`units.${parsed.unit}`, { num: parsed.value });
    },
    getConcatString(obj) {
      let str = obj.value;
      if (obj.unit) str += ` ${obj.unit}`;
      return str;
    },
  },
};
